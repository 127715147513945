@import "../../variable.scss";

.nft {
  margin: 6rem 0.5rem;

  @include media("mobile") {
    margin: 2.5rem 0.5rem;

  }

  @include media("tablet") {
    margin: 2.5rem 1rem;

  }
}