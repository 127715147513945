@import "../../variable.scss";

.bg_image {
  .main_row {
    padding: 6rem 0rem 8rem 0rem;

    @include media("mobile") {
      padding: 2rem 1rem;
    }

    @include media("tablet") {
      padding: 2rem 1rem;
    }

    .logo_col {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        @include media("mobile") {
          width: 100%;
        }
    
        @include media("tablet") {
          width: 100%;
        }
      }
    }

    .reg_col {
      .container {
        max-width: 520px;
        margin: 0 auto;
        background: transparent linear-gradient(180deg, rgba(244,244,244, 0.13) 0%, rgba(182, 182, 182, 0.1) 30%, rgba(0, 0, 0, 0) 100%) 0% 0% no-repeat padding-box;
        // box-shadow: 6px 6px 30px #000000;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 74px;

        @include media("mobile") {
          padding: 1rem 1.5rem;
        }

        @include media("tablet") {
          padding: 0rem 3rem;
        }

        .error {
          margin: 0.25rem auto;
          font-size: .875em;
          color: #dc3545;
        }

        .heading {
          h3 {
            font-family: 'Brandon-Grotesque-Black';
            color: white;
            font-size: 55px;
            letter-spacing: 3.3px;
            opacity: 1;
            @include media("mobile") {
              font-size: 50px;
            }

            @include media("tablet") {
              font-size: 50px;
            }
          }
        }

        .reg_google {
          border-radius: 4px !important;
          border: 1px solid #fcf7f4 !important;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          text-align: center;
          vertical-align: middle;
          margin-top: 2rem;
          max-width: 100%;
          background-color: transparent !important;
          color: white !important;
          font-size: 20px !important;
          font-family: 'Brandon-Grotesque' !important;
          @include media("mobile") {
            font-size: 18px !important;
          }

          @include media("tablet") {
            font-size: 18px !important;
          }

          div {
            background-color: transparent !important;
          }
        }

        .or {
          margin-top: 0.8rem;
          color: white;
          font-size: 20px;
          @include media("mobile") {
            font-size: 18px;
          }

          @include media("tablet") {
            font-size: 18px;
          }
        }

        .email {
          border: none;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          input {
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }
        }

        .password {
          border: none;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          margin-top: 1.4rem;
          align-items: center;
          position: relative;

          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          button {
            background-color: transparent;
            border: none;
          }
        }

        .check {
          margin: 1.4rem auto;
          a {
            text-decoration: none;
            color: white;
          }
        }
      }
    }

    .reg_btn {
      display: flex;
      margin-bottom: 1.4rem;

      button {
        padding: 17px 0;
        width: 100%;
        border: none;
        font-weight: bold;
        text-align: center;
        font-size: 22px;
        letter-spacing: 0.88px;
        color: #ffffff;
        opacity: 1;
        font-family: 'Brandon-Grotesque-Bold';
        background: transparent linear-gradient(108deg, #4283E5 0%, #6848B5 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        @include media("mobile") {
          font-size: 20px;
        }

        @include media("tablet") {
          font-size: 20px;
        }
      }
    }

    .have_account {
      p {
        color: white;
        font-size: 19.5px;
        white-space: nowrap;

        @include media("mobile") {
          font-size: 17.5px;
        }

        @include media("tablet") {
          font-size: 17.5px;
        }

        span {
          a {
            padding-left: 5px;
            font-size: 19.5px;
            white-space: nowrap;
            color: #ffffff;
            text-decoration: underline;
            cursor: pointer;

            @include media("mobile") {
              font-size: 17.5px;
            }

            @include media("tablet") {
              font-size: 17.5px;
            }
          }
        }
      }
    }
  }
}