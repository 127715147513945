@import "../../variable.scss";

.card {
  border: 1px solid black !important;
  border-radius: 1rem !important;

}

.card-body {
  border: none !important;
  padding: 1.5rem 1rem !important;

  @include media("mobile") {
    padding: 1rem !important;
  }
}

.card-subtitle {
  font-size: 22px !important;
  letter-spacing: 0.55px !important;
  color: #000000 !important;
  text-align: left !important;
  white-space: wrap !important;
}

.card-text {
  text-align: left !important;
  font-size: 20px !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
  font-weight: 600 !important;
  white-space: wrap !important;

}

.card-img-top {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}