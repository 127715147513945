@import "../../variable.scss";

.card {
  border: 0px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  .card-img-top{
    border-radius: 0px !important;
  }

  .card-body {
    border: none !important;
    padding: 0 !important;
    padding-top: 25px !important;

    @include media("mobile") {
      padding-top: 20px !important;
    }
  }

}
.coinbase-button {
  height: 0;
  visibility: hidden;
}


.card-subtitle {
  font-size: 20px !important;
  line-height: 29px;
  letter-spacing: 0.55px !important;
  color: #ffffff !important;
  text-align: left !important;
  font-weight: normal !important;
  white-space: wrap !important;
  text-transform: uppercase;
}

.card-text {
  font-size: 20px !important;
  line-height: 29px;
  letter-spacing: 0.55px !important;
  color: #ffffff !important;
  text-align: left !important;
  font-weight: normal !important;
  white-space: wrap !important;
  
  @include media("mobile") {
    font-size: 18px !important;
  }
}

.card-img-top {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.textfield-1 {
  width: 100%;
  position: relative;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 16px 26px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  color: #fff;
  border: 1px solid transparent;

  &:focus {

    width: 100%;
    position: relative;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 16px 26px;
    border: 1px solid white;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1;
    text-align: left;
  }

  &.is-invalid {
    border-color: red;
  }
 
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}


.back-link{
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 50px;
}