@import "../../variable.scss";

.main_container {
  padding: 2rem 10rem;

  @include media("mobile") {
    padding: 0px; padding-bottom: 50px;
  }

  .row {
    display: flex;
    margin: 0 -22px;

    @include media("mobile") {
      margin: 0px;
    }
    .left_col {
      padding: 0 22px 100px 22px;
      @include media("mobile") {
        padding: 0 20px 20px 20px;
      }
      display: flex;
      .card_container {
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top_img_sec {
          width: 100%;
          height: auto;
          position: relative;
          background-color: #00000078;

          @include media("mobile") {
            width: 100%;
            // height: 200px;
            position: relative;
            background-color: #00000078;
          }

          img {
            width: 100%;
            height: auto;
            
          }          

          .yellow_logo {
            position: absolute;
            top: -55px;
            right: 25px;
            width: 2.8rem;
          }
        }

        .content_container {
          // position: absolute;
          // left: 0.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          @include media("mobile") {
            // position: absolute;
            // top: 0;
            // left: 10px;
            bottom: 0.8rem;
            padding: 0rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          h6 {
            font-size: 18px;
            letter-spacing: 0.55px;
            color: #00baff;
            margin-bottom: 0.5rem;
            font-weight: bold;
          }

          h1 {
            margin-top: 0.5rem;
            font-size: 34px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0px;
            color: #00baff;
            text-transform: capitalize;
            line-height: 40px;
            font-family: "Brandon-Grotesque-Bold";

            @include media("mobile") {
              font-size: 28px;
              line-height: 34px;
            }

            @include media("tablet") {
              font-size: 19px;
              line-height: 34px;
            }
          }

          button {
            width: 100%;
            margin: 0 auto;
            margin-top: 0.5rem;
            font-size: 13px;
            border: none;
            outline: none;
            padding: 1rem 0.8rem;
            border-radius: 5px;
            color: white;
            letter-spacing: 1.04px;
            text-transform: uppercase;
            opacity: 1;
            font-weight: 900 !important;
            background: transparent linear-gradient(108deg, #4283E5 0%, #6848B5 100%) 0% 0% no-repeat padding-box;
            border-radius: 4px;
            font-family: "Brandon-Grotesque-Bold";
            margin-top: 0px;
            h4 {
              font-weight: 900 !important;
              font-size: 13px;
              margin: 0rem;
            }
          }
        }

        .divider {
          display: flex;
          justify-content: space-between;
          max-width: 100%;
          padding: 16px 0;
          @include media("mobile") {
            // flex-direction: column;
            justify-content: center;
            text-align: left;
          }

          @include media("tablet") {
            flex-direction: column;
            justify-content: center;
            text-align: left;
          }

          .left {
            max-width: 60%;
            width: calc(100% - 130px);
            p {
              font-size: 18px !important;
              letter-spacing: 0.5px !important;
              color: white !important;
              margin: 0;
              text-align: left !important;
            }

            @include media("mobile") {
              max-width: 100%;
              p {
                text-align: left !important;
              }
            }

            @include media("tablet") {
              max-width: 100%;
            }
          }

          .right {
            letter-spacing: 0.5px;
            width: 130px;
            opacity: 1;
            font-weight: 600;
            p {
              font-size: 18px !important;
              letter-spacing: 0.5px !important;
              color: white !important;
              margin: 0px !important;
              text-align: right !important;
            }

            @include media("mobile") {
              // margin: 0.2rem;
              // margin-top: 0.5rem;
              max-width: 100%;
            }

            @include media("tablet") {
              margin: 0.2rem;
              margin-top: 0.5rem;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
