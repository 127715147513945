.back_button {
  background: none !important;
  border: 0px !important;
  border-radius: 4px;
  opacity: 1;
  letter-spacing: 0.65px;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
    font-size: 20px;
    color: #4dcfff;
  }
}
