@import "../../variable.scss";

.main_container {
  padding: 2rem 10rem;
  h1 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
    color: white;
  }
  @include media("mobile") {
    padding: 2rem 2.5rem;
  }

  .row {
    display: flex;

    .left_col {
      padding: 0.5rem;

      .text_over_image {
        padding: 2rem 0rem 2rem 2rem;
        position: absolute;
        z-index: 1;
        text-align: left;

        p {
          font-size: 22px;
          letter-spacing: 0.55px;
          color: #ffffff;
          opacity: 1;
          margin-bottom: 0.5rem;
        }

        h2 {
          font-size: 60px;
          text-align: left;
          letter-spacing: 0px;
          color: #ffffff;
          text-transform: uppercase;
          line-height: 55px;
          opacity: 1;
        }

        button {
          margin-top: 1rem;
          background-color: #4badf9;
          border-radius: 4px;
          font-size: 13px;
          letter-spacing: 0.65px;
          border: none;
          outline: none;
          padding: 0.5rem 1rem;
          border-radius: 5px;
          color: #000000;
          text-transform: uppercase;
          opacity: 1;
        }
      }

      .yellow_logo {
        position: absolute;
        top: 25px;
        right: 25px;
      }

      .divider {
        max-width: 100%;
        text-align: left;

        @include media("mobile") {
          flex-direction: column;
          justify-content: center;
          text-align: left;
        }

        .left {
          max-width: 100%;
          margin-bottom: 20px;
          @include media("mobile") {
            max-width: 100%;
            margin: 0.2rem;
          }
        }

        .right {
          letter-spacing: 0.5px;
          max-width: 100%;
          opacity: 1;
          font-weight: 600;
          @include media("mobile") {
            margin: 0.2rem;
            margin-top: 0.5rem;
            max-width: 100%;
          }

          button {
            color: white;
            border-radius: 4px;
            border: none;
            outline: none;
            background: transparent linear-gradient(105deg, #4283E5 0%, #6848B5 100%) 0% 0% no-repeat padding-box;
            width: 100%;
          }
        }
      }
    }
  }
  button {
    display: flex;
    margin: auto;
    justify-content: center;
    border: 1px solid black;
    padding: 0.3rem 1rem;
    background: transparent linear-gradient(105deg, #4283E5 0%, #6848B5 100%) 0% 0% no-repeat padding-box;
    color: white;
    border-radius: 6px;
    cursor: pointer;
  }
}
