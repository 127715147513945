.loader {
  height: 80vh;
  text-align: center;
  margin-top: 50px;

  img {
    width: 110px;
  }

  .spinner {
    margin-top: 1rem;
    height: 70px;
    width: 70px;
    color: #00baff !important;
  }
  
}
