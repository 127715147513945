@import "../../variable.scss";

.header {
  // padding: 0.18rem 1.2rem;
  // background-color: transparent;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 40px 0px 40px 0px;
  z-index: 999;
  max-width: 1440px !important;
  margin: auto !important;
  @include media("mobile") {
    display: none !important;
  }

  @include media("tablet") {
    display: none !important;
  }

  .avatar_container {
    position: relative;
    height: 40px;

    img {
      height: 22px;
    }

    .dropdown {
      box-shadow: 0px -3px 9px -1px rgba(176, 176, 176, 0.75);
      -webkit-box-shadow: 0px -3px 9px -1px rgba(176, 176, 176, 0.75);
      -moz-box-shadow: 0px -3px 9px -1px rgba(176, 176, 176, 0.75);
      position: absolute;
      background-color: white;
      top: 45px;
      right: 0;
      padding: 1rem;
      text-align: left;
      letter-spacing: 0.45px;
      font-size: 16px;
      line-height: 30px;
      z-index: 1;
      font-weight: 600;

      li {
        list-style-type: none;
        text-transform: uppercase;
        white-space: nowrap;
        cursor: pointer;
      }

      li:hover {
        color: #00baff;
      }
    }

    .avatar {
      background-color: transparent;
      border: none;
    }
  }
}

// @media only screen and (min-width: 768px) {
//   // FOR DESKTOP AND TABLET
//   .header {
//     display: none !important;
//   }
// }
