@mixin media($breakpoint) {
    @if $breakpoint==desktop {
        @media only screen and (max-width: 1799px) {
            @content;
        }
    }

    @if $breakpoint==mobile {
        @media only screen and (max-width: 600px) {
            @content;
        }
    }

    @if $breakpoint==tablet {
        @media only screen and (max-width: 1025px) and (min-width: 601px) {
            @content;
        }
    }
}