@import "../../variable.scss";

.bg_image {
  .main_row {
    padding: 6rem 0rem 8rem 0rem;

    @include media("mobile") {
      padding: 2rem 1rem;
    }

    @include media("tablet") {
      padding: 2rem 1rem;
    }

    .successBox {
      color: white;

      h3 {
        font-size: 55px;
        font-weight: bold;
        letter-spacing: 3.3px;
        opacity: 1;
        display: flex;
        justify-content: center;

        @include media("mobile") {
          font-size: 50px;
        }

        @include media("tablet") {
          font-size: 50px;
        }
      }
    }

    .reg_col {
      .container {
        max-width: 862px;
        margin: 0 auto;
        padding: 0rem 10rem;
        background: transparent
          linear-gradient(
            180deg,
            rgba(244, 244, 244, 0.13) 0%,
            rgba(182, 182, 182, 0.1) 30%,
            rgba(0, 0, 0, 0) 100%
          )
          0% 0% no-repeat padding-box;
        // box-shadow: 6px 6px 30px #000000;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 74px;

        @include media("mobile") {
          padding: 0rem 1rem;
        }

        @include media("tablet") {
          padding: 0rem 3rem;
        }

        .error {
          margin: 0.25rem auto;
          font-size: 0.875em;
          color: #dc3545;
        }

        .heading {
          h3 {
            font-weight: 400;
            font-family: "Brandon-Grotesque";
            color: white;
            font-size: 55px;
            font-weight: bold;
            letter-spacing: 3.3px;
            opacity: 1;
            display: flex;
            justify-content: center;
            @include media("mobile") {
              font-size: 50px;
            }

            @include media("tablet") {
              font-size: 50px;
            }
          }
        }
        .google_wrapper {
          display: flex;
          justify-content: center;
          .reg_google {
            border-radius: 4px;
            border: 1px solid rgb(255, 255, 255) !important;
            max-width: 322px !important;
            opacity: 1;
            padding: 0.4rem 1rem;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            vertical-align: middle;
            margin-top: 2rem;
            max-width: 100%;
            background-color: transparent !important;
            color: white !important;
            font-size: 20px !important;
            font-family: "Brandon-Grotesque" !important;

            @include media("mobile") {
              font-size: 18px !important;
            }

            @include media("tablet") {
              font-size: 18px !important;
            }

            div {
              background-color: transparent !important;
            }
          }
        }
        .or {
          margin-top: 0.8rem;
          color: white;
          font-size: 20px;
          text-align: center;

          @include media("mobile") {
            font-size: 18px;
          }

          @include media("tablet") {
            font-size: 18px;
          }
        }

        .row {
          display: flex;
          margin-bottom: 20px;

          @include media("mobile") {
            flex-direction: column;
          }

          .col {
            flex: 1;
            &:first-child {
              margin-right: 15px;
              @include media("mobile") {
                margin-right: 0px;
                margin-bottom: 20px;
              }
            }
            &:last-child {
              margin-left: 15px;
              @include media("mobile") {
                margin-left: 0px;
              }
            }
          }
        }

        .fname {
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }
        }

        .lname {
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }
        }

        .text_field {
          // border: 1px solid #fcf7f4;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }
          > div {
            padding: 14px 0px;
          }
        }

        .text_field_date {
          // border: 1px solid #fcf7f4;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          // margin-top: 1.4rem;
          @include media("mobile") {
            input[type="date"]:before {
              color: rgb(255, 255, 255);
              content: attr(placeholder) !important;
              margin-right: 0.5em;
            }
            input[type="date"]:focus:before {
              content: "" !important;
            }
          }

          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }
        }

        .password {
          // border: 1px solid #fcf7f4;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          // margin-top: 1.4rem;
          align-items: center;
          position: relative;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          button {
            background-color: transparent;
            border: none;
          }
        }

        .check {
          display: flex;
          margin-top: 1.4rem;
          text-align: left;

          input {
            border: 1px solid #fcf7f4;
            margin-right: 0.5rem;
            opacity: 1;
            margin-top: 0.25rem;
            align-self: flex-start;
          }

          label {
            color: white;
            font-size: 15px;
            flex-wrap: nowrap;

            @include media("mobile") {
              font-size: 14px;
            }

            @include media("tablet") {
              font-size: 14px;
            }

            span {
              a {
                padding-left: 5px;
                color: #0089c6;
                font-size: 15.5px;
                text-decoration: underline;
                flex-wrap: nowrap;

                @include media("mobile") {
                  font-size: 14px;
                }

                @include media("tablet") {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .reg_btn {
          display: flex;
          margin-bottom: 1.4rem;
          margin-top: 2.4rem;
          justify-content: center;
          button {
            font-family: "Brandon-Grotesque";
            width: 100%;
            max-width: 322px;
            padding: 17px 0;
            border: none;
            border-radius: 5px;
            font-weight: bold;
            text-align: center;
            font-size: 22px;
            letter-spacing: 0.88px;
            color: white;
            opacity: 1;
            background: transparent
              linear-gradient(108deg, #4283e5 0%, #6848b5 100%) 0% 0% no-repeat
              padding-box;
            border-radius: 4px;
            @include media("mobile") {
              font-size: 20px;
            }

            @include media("tablet") {
              font-size: 20px;
            }
          }
        }

        .have_account {
          p {
            color: white;
            font-size: 19.5px;
            white-space: nowrap;
            text-align: center;
            @include media("mobile") {
              font-size: 17.5px;
            }

            @include media("tablet") {
              font-size: 17.5px;
            }

            span {
              a {
                padding-left: 5px;
                font-size: 19.5px;
                white-space: nowrap;
                color: #0089c6;
                text-decoration: underline;
                cursor: pointer;

                @include media("mobile") {
                  font-size: 17.5px;
                }

                @include media("tablet") {
                  font-size: 17.5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
