// font family*
@font-face {
  font-family: Brandon-Grotesque;
  src: url("Fonts/brandon-grotesque-light.eot");
  src: url("Fonts/brandon-grotesque-light.eot?#iefix") format("embedded-opentype"), url("Fonts/brandon-grotesque-light.woff2") format("woff2"),
    url("Fonts/brandon-grotesque-light.woff") format("woff"), url("Fonts/brandon-grotesque-light.ttf") format("truetype");
}

@font-face {
  font-family: Brandon-Grotesque-Black;
  src: url("Fonts/brandon-grotesque-black.eot");
  src: url("Fonts/brandon-grotesque-black.eot?#iefix") format("embedded-opentype"), url("Fonts/brandon-grotesque-black.woff2") format("woff2"),
  url("Fonts/brandon-grotesque-black.woff") format("woff"), url("Fonts/brandon-grotesque-black.ttf") format("truetype");
}

@font-face {
  font-family: Brandon-Grotesque-Bold;
  src: url("Fonts/BrandonGrotesque-Bold.eot");
  src: url("Fonts/BrandonGrotesque-Bold.eot?#iefix") format("embedded-opentype"), url("Fonts/BrandonGrotesque-Bold.woff2") format("woff2"),
  url("Fonts/BrandonGrotesque-Bold.woff") format("woff"), url("Fonts/BrandonGrotesque-Bold.ttf") format("truetype");
}

body {
  font-family: Brandon-Grotesque, sans-serif;
  background-image: url("./Assets/redesign/background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
}