@import "../../../variable.scss";

.main_container {
  background-color: rgb(0, 0, 0);
  padding: 1rem 0;
  @include media("mobile") {
    padding: 1rem 1rem;
  }

  @include media("tablet") {
    padding: 1rem 1rem;
  }

  .border {
    border-top: 1px solid #ffffff;
    padding-bottom: 1.5rem;
  }
  .inner_container {
    padding: 0px 35px;
    display: flex;
    align-items: flex-end;
    @include media("mobile") {
      flex-direction: column;
      padding: 0px;
    }

    @include media("tablet") {
      flex-direction: column;
    }
    .col_one {
      color: white;
      display: flex;
      float: left;
      flex-direction: column;
      @include media("mobile") {
        width: 100% !important;
        margin: auto !important;
      }

      @include media("tablet") {
        width: 100% !important;
        margin: auto !important;
      }

      .social_nav {
        margin-bottom: 2rem;

        @include media("mobile") {
          margin-top: 1.4rem;
        }

        @include media("tablet") {
          margin-top: 3rem;
        }

        .div {
          display: flex;

          @include media("mobile") {
            justify-content: space-around;
            text-align: center;
          }

          @include media("tablet") {
            justify-content: space-around;
            text-align: center;
          }
        }
      }
      p {
        font-weight: 600;
        font-size: 18px;
      }
      @include media("mobile") {
        margin: 0rem;
        text-align: center;
        justify-content: center;
      }

      @include media("tablet") {
        margin: 0rem;
        text-align: center;
        justify-content: left;
      }
    }

    .col_two {
      color: white;
      margin-right: 0rem;
      display: flex;
      justify-content: flex-end;
      @include media("mobile") {
        width: 100% !important;
        justify-content: center !important;
        margin: auto !important;
      }

      @include media("tablet") {
        width: 100% !important;
        justify-content: center !important;
        margin: auto !important;
      }

      .div {
        display: flex;
        float: right;

        @include media("mobile") {
          float: none;
          justify-content: space-between;
        }
        @include media("tablet") {
          float: none;
          justify-content: space-between;
        }
        .div_one {
          margin-right: 1.5rem;
          @include media("mobile") {
            margin-right: 1rem;
          }

          p {
            white-space: nowrap;
            font-weight: 600;
            font-size: 18px;
            a {
              text-decoration: none;
              color: white;
              font-weight: 600;
              font-size: 18px;
            }
          }
        }

        .div_two {
          p {
            white-space: nowrap;
            font-weight: 600;
            font-size: 18px;
            a {
              text-decoration: none;
              color: white;
              font-weight: 600;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
