@import "../../variable.scss";

.main_container {
  padding: 0rem 4.5rem;

  @include media("mobile") {
    padding: 20px 15px;
  }

  @include media("tablet") {
    padding: 2rem 4.5rem;
  }

  .row {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .left_col {
      img {
        width: 100%;
        margin: auto;
        // height: 450px;
      }
      .Wrapper {
        display: flex;
        justify-content: flex-start;
        // justify-content: flex-start;
        align-items: center;
        // vertical-align: middle;
        gap: 1rem;
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;

        p {
          margin-bottom: 0 !important;
          color: white;
        }
      }
    }
    h3 {
      display: flex;
      margin-bottom: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.55px;
      color: #000000;
      opacity: 1;
      font-size: 22px;
    }

    .divider {
      display: flex;
      justify-content: space-between;
      max-width: 100%;

      @include media("mobile") {
        flex-direction: column;
        justify-content: center;
        text-align: left;
      }

      .left {
        max-width: 60%;
        // margin-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        div {
          font-size: 18px !important;
        }

        p {
          font-size: 18px !important;
        }

        @include media("mobile") {
          max-width: 100%;
          // margin: 0.2rem;
        }
      }

      .right {
        margin-right: 1rem;
        margin-top: 1.8rem;
        letter-spacing: 0.5px;
        color: #000000;
        max-width: 40%;
        opacity: 1;
        font-weight: 400;
        font-weight: 600;
        display: none;
        p {
          font-size: 18px !important;
        }

        @include media("mobile") {
          margin: 0.2rem;

          margin-top: 0.5rem;
          max-width: 100%;
        }
      }
    }
  }

  .right_col {
    //   margin-left: 4rem;

    @include media("mobile") {
      // margin: 0rem;
      margin-top: 2rem;
    }

    @include media("tablet") {
      // margin: 0rem;
      margin-top: 2rem;
    }

    .div {
      padding: 60px 80px;
      // border: 1px solid black;
      border-radius: 1rem;
      opacity: 1;
      background: transparent
        linear-gradient(
          180deg,
          rgba(244, 244, 244, 0.13) 0%,
          rgba(182, 182, 182, 0.1) 30%,
          rgba(0, 0, 0, 0) 100%
        )
        0% 0% no-repeat padding-box;

      @include media("mobile") {
        padding: 1.5rem;
      }

      h4 {
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        margin-bottom: 20px;
        color: #fff;
      }

      .cards_container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        div {
          width: 30%;
          background: rgba(255, 255, 255, 0.75);
          padding: 10px;
          align-items: center;
          display: flex;
          text-align: center;
          justify-content: center;
          border-radius: 4px;

          img {
            max-height: 35px;
            align-items: center;
            max-width: 50px;
          }
        }
      }

      .payment_heading {
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        margin: 24px 0rem 10px;
      }

      .input {
        max-width: 100%;
        margin-bottom: 1.2rem;

        input {
          width: 100%;
          height: 50px;
          letter-spacing: 0.4px;
        }

        label {
          color: #ffffff;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          a {
            color: #fff;
            text-decoration: none;
            margin: 0 5px;
          }
        }

        input[type="checkbox"] {
          margin-right: 17px;
          height: 24px;
          width: 24px;
          background-color: rgba(255, 255, 255, 0.2);
        }

        ::placeholder {
          font-size: 18px;
          color: rgba(255, 255, 255, 0.3);
        }

        + .buy_btn {
          button {
            width: 100%;
            margin: 0 auto;
            margin-top: 0.5rem;
            font-size: 16px;
            border: none;
            outline: none;
            padding: 1rem 0.8rem;
            border-radius: 5px;
            color: white;
            letter-spacing: 1.28px;
            text-transform: uppercase;
            opacity: 1;
            font-weight: 900 !important;
            background: transparent
              linear-gradient(108deg, #4283e5 0%, #6848b5 100%) 0% 0% no-repeat
              padding-box;
            border-radius: 4px;
            font-family: "Brandon-Grotesque-Bold";
            margin-top: 0px;
            border: 0px !important;
            line-height: 22px;
          }
        }
      }

      .danger {
        color: red;
        font-size: 16px;
        font-weight: bold;
      }

      .buy_btn {
        max-width: 100%;
        margin-top: 25px;

        button {
          width: 100%;
          background-color: transparent;
          border: 1px solid #fff !important;
          color: white;
          border: none;
          padding: 17px 30px;
          border-radius: 4px;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;

          @include media("mobile") {
            padding: 12px 20px;
            font-size: 10px;
          }
        }
      }

      .or {
        margin: 10px 0;
        text-align: center;
        color: #fff;
        text-transform: none;
        p {
          font-weight: bold;
          font-size: 22px;
        }
      }

      .paypal_btn {
        max-width: 100%;

        button {
          width: 100%;
          display: flex;
          justify-content: center;
          border: none;
          padding: 0.5rem 0rem;
          font-weight: 600;

          @include media("mobile") {
            font-size: 14px;
          }

          img {
            width: 5rem;
            margin-left: 1rem;

            @include media("mobile") {
              width: 4rem;
              padding-top: 3px;
            }
          }
        }
      }

      .info {
        font-size: 15px;
        line-height: 22px;
        color: #fff;
        margin-bottom: 0px !important;

        &.mt-3 {
          margin-top: 24px !important;
        }
      }
    }
  }
}
