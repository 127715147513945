@import "../../variable.scss";

.main_container {
  background-color: #000000;
  // padding: 0rem 5rem;
  padding-top: 36px;

  @include media("mobile") {
    padding: 0;
  }

  @include media("tablet") {
    padding: 1.5rem 0rem;
  }

  .row {
    display: flex;
    justify-content: space-between;
    @include media("mobile") {
      padding-bottom: 1rem;
      padding-top: 1rem;
      flex-direction: column;
    }

    @include media("tablet") {
      padding-bottom: 1rem;
      padding-top: 1rem;
      flex-direction: column;
    }

    .col_one {
      margin: 50px 10px 60px 50px;
      @include media("tablet") {
        margin: 10px;
      }
      @include media("mobile") {
        margin: 10px;
      }
      div {
        text-align: left;
        padding-bottom: 10px;
      }

      img {
        width: 138px;
        height: 52px;

        @include media("mobile") {
          margin-right: 0rem;
          display: flex;
          margin: auto;
        }

        @include media("tablet") {
          margin-right: 0rem;
          display: flex;
          margin: auto;
        }
      }

      ul {
        padding-left: 0rem;
        margin-top: 0.5rem;

        @include media("mobile") {
          padding-left: 0px;
          padding-bottom: 0rem;
        }

        @include media("tablet") {
          padding-left: 0px;
          padding-bottom: 0rem;
        }

        li {
          list-style-type: none;
          color: #ffffff;
          opacity: 1;
          letter-spacing: 0.45px;
          text-align: left;
          line-height: 2rem;
          font-weight: 600;
          font-size: 18px;

          a {
            text-decoration: none;
            color: white;
          }

          @include media("mobile") {
            display: flex;
            justify-content: center;
            padding-left: 0rem;
            text-align: center;
            line-height: 30px;
          }

          @include media("tablet") {
            display: flex;
            justify-content: center;
            padding-left: 0rem;
            text-align: center;
            line-height: 30px;
          }
        }
      }
    }

    .col_two {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 50px 25px;

      @include media("tablet") {
        margin: 10px;
      }
      @include media("mobile") {
        margin: 10px;
      }
      @include media("mobile") {
        margin-bottom: 1rem;
      }

      @include media("tablet") {
        margin-bottom: 1rem;
      }
      .anchor_link {
        text-decoration: none;
      }
      h2 {
        text-align: left;
        margin-bottom: 1.25rem;
        font-size: 20px;
        font-weight: 1000;
        color: #ffffff;
        opacity: 1;
        letter-spacing: 0.5px;

        @include media("mobile") {
          display: flex;
          justify-content: center;
        }

        @include media("tablet") {
          display: flex;
          justify-content: center;
        }
      }

      p {
        text-align: left;
        max-width: 17rem;
        color: #ffffff;
        opacity: 1;
        letter-spacing: 0px;
        font-weight: 600;
        font-size: 18px;
        @include media("mobile") {
          text-align: center;
          margin: auto;
          padding-bottom: 1rem;
        }

        @include media("tablet") {
          text-align: center;
          padding-bottom: 1rem;
          margin: auto;
        }
      }
    }

    .col_three {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 50px 25px;
      @include media("tablet") {
        margin: 10px;
      }
      @include media("mobile") {
        margin: 10px;
      }
      @include media("mobile") {
        margin-bottom: 1rem;
      }

      @include media("tablet") {
        margin-bottom: 1rem;
      }
      .anchor_link {
        text-decoration: none;
      }
      h2 {
        margin-bottom: 1.25rem;
        text-align: left;
        font-size: 20px;
        font-weight: 1000;
        color: #ffffff;
        opacity: 1;
        letter-spacing: 0.5px;

        @include media("mobile") {
          display: flex;
          justify-content: center;
        }

        @include media("tablet") {
          display: flex;
          justify-content: center;
        }
      }

      p {
        text-align: left;
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
        opacity: 1;
        letter-spacing: 0px;
        text-decoration: none;

        a {
          color: white;
          text-decoration: none;
        }

        @include media("mobile") {
          text-align: center;
          margin: auto;
        }

        @include media("tablet") {
          text-align: center;
          margin: auto;
        }
      }

      .btns {
        display: flex;

        @include media("mobile") {
          display: flex;
          justify-content: center;
          padding-bottom: 1rem;
        }

        @include media("tablet") {
          display: flex;
          justify-content: center;
          padding-bottom: 1rem;
        }

        .email {
          background-color: white;
          color: black;
          font-size: 15px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border: transparent;
          padding: 0.2rem 1rem;
          max-width: 40%;

          outline: none;
        }

        .email::placeholder {
          color: black;
        }

        .email:focus {
          outline: none;
        }

        .sign_up {
          background-color: #333333;
          color: white;
          padding: 0.4rem 1rem;
          border: transparent;
          font-size: 15px;
          font-weight: 600;
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
        }
      }
    }

    .col_four {
      text-align: left;
      margin: 50px 50px 60px 10px;
      @include media("tablet") {
        margin: 10px;
      }
      @include media("mobile") {
        margin: 10px;
      }
      h2 {
        font-size: 20px;
        font-weight: 1000;
        color: #ffffff;
        opacity: 1;
        letter-spacing: 0.5px;
        margin-bottom: 1.25rem;

        @include media("mobile") {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
        }

        @include media("tablet") {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
        }
      }

      .currency_works {
        img {
          width: 16.25rem;
          @include media("tablet") {
            width: 11.8rem;
          }
          @include media("mobile") {
            width: 11.8rem;
          }
        }
        @include media("mobile") {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        @include media("tablet") {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .social_nav {
        margin-top: 1.4rem;

        @include media("mobile") {
          margin-top: 1.4rem;
        }

        @include media("tablet") {
          margin-top: 3rem;
        }

        .div {
          display: flex;

          @include media("mobile") {
            justify-content: space-around;
            text-align: center;
          }

          @include media("tablet") {
            justify-content: space-around;
            text-align: center;
          }
        }
      }
    }
  }
}
