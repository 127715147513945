@import "../../variable.scss";

.div {
    padding-top: 3rem;
    margin-bottom: 3rem;

    @include media("mobile") {
        padding-top: 1rem;

    }

    @include media("tablet") {
        padding-top: 1rem;

    }
}