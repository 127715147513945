@import "../../variable.scss";

.payment {
  padding: 85px 0 40px;
  .container {
    text-align: center;

    .box {
      justify-content: center;
      width: 80%;
      display: flex;
      margin: auto;
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .col {
        .popup {
          button {
            background-color: transparent;
            border: none;
            color: #1a53ce;
            float: right;
            margin-top: 1rem;
            font-size: 1.6rem;
            margin-right: 1rem;
            z-index: 1;
          }
        }
        .popup_box {
          padding: 63px 100px 100px;
          background: transparent
            linear-gradient(
              180deg,
              rgba(244, 244, 244, 0.13) 0%,
              rgba(182, 182, 182, 0.1) 30%,
              rgba(0, 0, 0, 0) 100%
            )
            0% 0% no-repeat padding-box;
          border-radius: 30px !important;
          color: rgb(0, 0, 0);
          border: 0px !important;
          @media only screen and (max-width: 600px) {
            padding: 30px 30px 30px;
          }

          @include media("mobile") {
            padding: 18px;
          }
        
          @include media("tablet") {
            padding: 28px;
          }

          .form-action {
            margin-top: 42px;
          }
          input {
            width: 100%;
            border: 1px solid transparent;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            color: #fff;
            padding: 14px 28px;
          }
          ::placeholder {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.3);
          }
          :focus {
            outline: none;
          }

          .group_button_container {
            text-align: center;
            margin: 10px 0 40px 0;
            @include media("mobile") {
              width: 100%;
            }
            button {
              background: none;
              font-size: 20px !important;
              letter-spacing: 0.6px !important;
              text-transform: capitalize !important;              
            }
            .button_active {
              background-color: transparent !important;
              border: none;
              border-radius: 0 !important;
              color: white;
              font-weight: bold;
              font-size: 20px;
              box-shadow: none !important;
              letter-spacing: 0.6px;
              border-bottom: 3px solid #00baff !important;
              padding: 0px !important;

              @include media("mobile") {
                font-size: 18px;
                border-bottom: none !important;
              }
            }

            .button_inactive {
              background-color: transparent !important;
              border: none;
              border-radius: 0 !important;
              color: white !important;
              font-weight: bold;
              font-size: 20px;
              box-shadow: none !important;
              padding: 0px !important;
              opacity: 0.5;

              @include media("mobile") {
                font-size: 18px;
              }
            }
          }
        }
        .popup_title {
          font-weight: 600;
          letter-spacing: 0.01em;
          color: #fff;
          font-size: 34px;
          line-height: 44px;
          margin-bottom: 30px;

          @media only screen and (max-width: 600px) {
            margin-bottom: 20px;
            font-size: 26px;
            line-height: 30px;
          }
        }

        button {
          width: 100%;
          margin: 0 auto;
          margin-top: 0.5rem;
          font-size: 16px;
          border: none;
          outline: none;
          padding: 1rem 0.8rem;
          border-radius: 5px;
          color: white;
          letter-spacing: 1.28px;
          text-transform: uppercase;
          opacity: 1;
          font-weight: 900 !important;
          background: transparent
            linear-gradient(108deg, #4283e5 0%, #6848b5 100%) 0% 0% no-repeat
            padding-box;
          border-radius: 4px;
          font-family: "Brandon-Grotesque-Bold";
          margin-top: 0px;
          border: 0px !important;
          line-height: 22px;
        }
      }
    }
  }
}
