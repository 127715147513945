@import "../../variable.scss";

.body {
  padding: 48px;

  @include media("mobile") {
    padding: 24px 10px;
  }

  background-color: #000000;
  .inner_div {
    text-align: center;
    background-color: #000000;
    @include media("mobile") {
      border: 2px solid black;
      padding: 2.5rem 1.5rem;
    }
  
    h2 {
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 1.75px;
      color: #fff;
      opacity: 1;
      font-family: 'Brandon-Grotesque';
      @include media("mobile") {
        font-size: 1.4rem;
      }
    }
  
    .buttonWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button {
        background: transparent linear-gradient(105deg, #4283E5 0%, #6848B5 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;
        width: 100%;
        max-width: 286px;
        margin-top: 1rem;
        padding: 10px 0rem;
        font-size: 0.8rem;
        letter-spacing: 1.04px;
        color: #F8F8F8;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
  }
}