@import "../../variable.scss";

.btn-group {
  @include media("mobile") {
    flex-wrap: wrap !important;
  }

  @include media("tablet") {
    flex-wrap: wrap !important;
  }
}

.btn-secondary {
  margin: 0rem 1.5rem !important;
  padding: 0.5rem 3rem !important;
  border-radius: 4px !important;

  @include media("mobile") {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  @include media("tablet") {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
}