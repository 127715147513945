.container {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .85);
  flex-direction: column;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  z-index: 1000;

  .loadingContainer {
    padding: 50px;

    .spinner {
      margin-top: 1rem;
      height: 70px;
      width: 70px;
      color: #00baff !important;
    }

    .spinner>div {
      background-color: white !important;
    }
  }
}