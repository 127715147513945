@import "../../variable.scss";

.password {
  svg {
    color: white !important;
  }
  border: none;
  opacity: 1;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  input {
    border: none;
    background-color: transparent !important;
    border-radius: 4px;
    color: #fff;
    // padding: 14px 28px;
    float: left;
    width: 100%;
    font-size: 20px;
    @include media("mobile") {
      font-size: 18px;
    }
    @include media("tablet") {
      font-size: 18px;
    }
  }

  ::placeholder {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.3);
  }

  :focus {
    outline: none;
    font-size: 20px;

    @include media("mobile") {
      font-size: 18px;
    }

    @include media("tablet") {
      font-size: 18px;
    }
  }
}
