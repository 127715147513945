.bg-light {
  background-color: white !important;
}

.nav-link {
  color: white !important;
  font-size: 16px !important;
  letter-spacing: 0.45px;
  font-weight: 400;

  &.active {
    font-weight: 600;
  }
}
.header-nav {
  padding: 0px 80px !important;
}
.vertical-align {
  transform: translate(-98px, 6px);
}
.navbar-list {
  transform: translateX(68px);
}
.vertical-icon-align {
  transform: translateY(2px);
  font-size: 17px;
}
.navbar-text {
  .nav-item {
    display: flex;

    .nav-link {
      button {
        // background-color: #00baff;
        background: transparent;
        border: 2px solid #4dcfff;
        border-radius: 4px;
        opacity: 1;
        padding: 8px 26px;
        font-size: 13px;
        font-weight: bold;
        color: white;
        white-space: nowrap;
        min-width: 12.6rem;
      }
    }
  }
}
