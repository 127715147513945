

.hero_container {
  background-image: url("../../Assets/HERO.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 0rem !important;

  .overlay {
    min-height: 63vh !important;
    // opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      align-items: center;
      justify-content: center;

      img {
        width: 5rem;
        margin-bottom: 2rem;

      }
    }

    .main_heading {
      max-width: 1300px;
      // margin: auto;
      padding-left: 15px;
      padding-right: 15px;
      h1 {
        text-align: center;
        font-size: 40px;
        color: white;
        font-weight: 600;
        display: flex;
        margin-bottom: 5rem;
        flex-direction: column;
        text-transform: uppercase;
        letter-spacing: 2.4px;
        font-family: 'Brandon-Grotesque-Bold';
      }
    }
  }
}