@import "../../variable.scss";

.rhap_progress-indicator {
  background: #00baff !important;
}
.rhap_download-progress {
  background: #00bbff71 !important;
}
.rhap_progress-filled {
  background: #00baff !important;
}
.rhap_main-controls {
  button {
    svg {
      color: #00baff !important;
    }
  }
}
.rhap_total-time {
  color: #00baff !important;
}
.rhap_volume-bar {
  background: #00bbff71 !important;
}
.rhap_volume-indicator {
  background: #00baff !important;
}
.rhap_container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include media("mobile") {
    height: 12rem !important;
  }
  @include media("tablet") {
    height: 15rem !important;
  }
}
