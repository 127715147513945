@import "../../variable.scss";

.imgContainer {
  width: 100% !important;
  height: auto !important;
  border: none !important;
 
  @include media("mobile") {
    width: 100% !important;
    height: auto !important;
  }

  @include media("tablet") {
    width: 100% !important;
    height: auto !important;
  }
}

.main_container {
  @include media("mobile") {
    padding: 1.5rem 2rem;
  }

  @include media("tablet") {
    padding: 1.5rem 2rem;
  }

  .buttons_container {
    padding: 2rem 0rem;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // text-align: center;

    @include media("mobile") {
      // flex-direction: column;
    }

    @include media("tablet") {
      // flex-direction: column;
    }
  }

  .second_container {
    color: #ffffff;

    padding-top: 3rem;
    @include media("mobile") {
      padding-top: 0rem;
    }
    @include media("tablet") {
      padding-top: 0rem;
    }
    .player_container {
      border-top: 2px solid rgb(196, 193, 193);
      margin-top: 2rem;
      padding: 1rem;
      p {
        font-size: 1.2rem;
        font-weight: bolder;
      }
    }
    .main_div {
    
      .col1_container {
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        div{
          width: 68.5%;
          border-top: none;
          border-radius: 25px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          p {          
            padding-left: 1rem;
            padding-top:1rem;
            font-size: 1.2rem;
            padding-bottom: 1rem;
          }
          
        }
      
        @include media("tablet") {
          padding: 0;
          margin: 1rem auto;

          
        }

        @include media("mobile") {
          padding: 0;
          margin: 1rem auto;
          
        }
      }

      .col2_container {
        margin-bottom: 1rem;

        @include media("mobile") {
          padding-right: 0rem;
        }

        @include media("tablet") {
          padding-right: 0rem;
        }
      p{
        white-space: pre-line;
      }
      .detail_div{
        white-space: pre-line;
      }
        
          .heading {
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.5px;
            text-align: left;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

      }
    }
  }
}
