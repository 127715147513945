@import "../../variable.scss";

.bg_image {
  background-image: url("../../Assets/reg_cover.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;

  .main_row {
    padding: 6rem 0rem 8rem 0rem;

    @include media("mobile") {
      padding: 2rem 1rem;
    }

    @include media("tablet") {
      padding: 2rem 1rem;
    }

    .reg_col {
      .container {
        padding: 0rem 10rem;

        @include media("mobile") {
          padding: 0rem 3rem;
        }

        @include media("tablet") {
          padding: 0rem 3rem;
        }

        .heading {
          h3 {
            color: white;
            font-size: 55px;
            font-weight: bold;
            letter-spacing: 3.3px;
            opacity: 1;
            display: flex;
            justify-content: center;

            @include media("mobile") {
              font-size: 50px;
            }

            @include media("tablet") {
              font-size: 50px;
            }
          }
        }

        .reg_google {
          border: 1px solid #fcf7f4 !important;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          text-align: center;
          vertical-align: middle;
          margin-top: 2rem;
          max-width: 100%;
          background-color: transparent !important;
          color: white !important;
          font-size: 20px !important;

          @include media("mobile") {
            font-size: 18px !important;
          }

          @include media("tablet") {
            font-size: 18px !important;
          }

          div {
            background-color: transparent !important;
          }
        }

        .or {
          margin-top: 0.8rem;
          color: white;
          font-size: 20px;
          text-align: center;

          @include media("mobile") {
            font-size: 18px;
          }

          @include media("tablet") {
            font-size: 18px;
          }
        }

        .email {
          border: 1px solid #fcf7f4;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          margin-bottom: 1.4rem;

          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }
        }

        .password {
          border: 1px solid #fcf7f4;
          opacity: 1;
          padding: 0.4rem 1rem;
          display: flex;
          margin-bottom: 1.4rem;
          align-items: center;

          input {
            background-color: transparent;
            border: none;
            float: left;
            width: 100%;
            color: white;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
          }

          :focus {
            outline: none;
            font-size: 20px;

            @include media("mobile") {
              font-size: 18px;
            }

            @include media("tablet") {
              font-size: 18px;
            }
          }

          button {
            background-color: transparent;
            border: none;
          }
        }

        .check {
          //   display: flex;
          margin-bottom: 1.4rem;
          text-align: end;

          a {
            text-decoration: none;
            color: white;
          }
        }
      }
    }

    .reg_btn {
      display: flex;
      margin-bottom: 1.4rem;

      button {
        width: 100%;
        padding: 0.4rem;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        font-size: 22px;
        letter-spacing: 0.88px;
        color: #000000;
        opacity: 1;

        @include media("mobile") {
          font-size: 20px;
        }

        @include media("tablet") {
          font-size: 2rem;
        }
      }
    }

    .have_account {
      text-align: center;

      p {
        color: white;
        font-size: 2rem;
        white-space: nowrap;

        @include media("mobile") {
          font-size: 1rem;
        }

        @include media("tablet") {
          font-size: 1rem;

        }

        span {
          a {
            padding-left: 5px;
            font-size: 19.5px;
            white-space: nowrap;
            color: #0089c6;
            text-decoration: underline;
            cursor: pointer;

            @include media("mobile") {
              font-size: 17.5px;
            }

            @include media("tablet") {
              font-size: 17.5px;
            }
          }
        }
      }
    }
  }
}