@import "../../variable.scss";

.main_container {
  padding: 9rem 10rem 0rem 10rem;

  @include media("mobile") {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
    padding-top: 0px;
  }

  .page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media("mobile") {
      flex-wrap: wrap;
    }
    h1 {
      color: white;
      font-size: 52px;
      margin: 0px !important;
      font-weight: 600;

      @include media("mobile") {
        font-size: 30px;
        margin-bottom: 20px !important;
        text-align: center;
        width: 100%;
        padding-top: 30px;
      }
    }
    .group_button_container {
      text-align: center;   
      @include media("mobile") {
        width: 100%;
      }   
      .button_active {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        color: white;
        font-weight: bold;
        font-size: 20px;
        box-shadow: none !important;
        letter-spacing: 0.6px;
        border-bottom: 3px solid #00BAFF;
        padding: 0px !important;

        @include media("mobile") {
          font-size: 18px;
        }
      }
  
      .button_inactive {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        color: white !important;
        font-weight: bold;
        font-size: 20px;
        box-shadow: none !important;
        padding: 0px !important;
        opacity: 0.5;

        @include media("mobile") {
          font-size: 18px;
        }
      }
    }
  }
}