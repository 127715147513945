.no-scroll {
  overflow: hidden;
}

@media only screen and (min-width: 1025px) {

  // FOR DESKTOP AND TABLET
  .main_div {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  /* phones */

  //   write css here
  .menuButton {
    display: flex;
    height: auto;
    background-color: transparent;
    position: relative;
    z-index: 11;

    .smLogo {
      margin: auto;

      & img {
        width: 6rem;
      }
    }

    text-align: center;

    .togButton {
      margin-top: 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem;

      button {
        background-color: transparent !important;
        border: none !important;
        color: white !important;
        font-size: 20px;
        font-weight: 800;
      }
    }
  }

  .sideNavUp {
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;

    button {
      background-color: transparent;
      border: none;
      color: white;
      font-size: 20px;
      float: right;
      font-weight: 600;
      margin-right: 1rem;
      text-align: center;
    }



    & img {
      border-radius: 50%;
      max-height: 100%;
      height: 60px;
    }
  }

  .sideNav {
    transition: 5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.973) !important;
    position: absolute;
    width: 250px !important;
    left: 0;
    border: 1px solid gray;
    height: 92vh !important;
    z-index: 1000;
    background-color: white;
    text-align: left;
    overflow: scroll;

    ul {
      margin-top: 30px;
      text-decoration: none;
      padding: 0rem;

      .link {
        text-decoration: none;
      }

      li {
        color: white !important;
        text-transform: uppercase;
        text-decoration: none !important;
        list-style-type: none;
        padding: 8px 0px;
        padding-top: 15px !important;
        padding-left: 20px !important;
        padding-bottom: 15px;
        border-top: 1px solid gray;
      }

      .canc {
        color: #00baff !important;
      }

      .liDropStyle {
        color: gray !important;
        font-size: 11px;
        background-color: white !important;

        padding: 8px 0px !important;
        padding-top: 15px !important;
        padding-left: 15px !important;
        margin-top: 0px !important;
        border-top: 1px solid gray;
      }
    }
  }

  .bottomButton {
    text-align: center;

    .loginButton {
      background-color: #00baff !important;
      border: none;
    }
  }
}